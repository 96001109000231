<template>
    <div>
        <div class="filter-panel">
            <CSSelect>
                <el-date-picker
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择"
                    v-model="queryParams.startDate"
                >
                </el-date-picker>
            </CSSelect>
            <span class="inline-gray" style="margin-left: -10px;"> </span>
            <CSSelect>
                <el-date-picker
                    type="datetime"
                    v-model="queryParams.endDate"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择"
                >
                </el-date-picker>
            </CSSelect>
            <input type="text" class="cs-input" v-model="queryParams.search"
                placeholder="搜索无人机/任务名称"
            >
            <button class="btn btn-primary" @click="getFlightLog()">查询</button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="60" :theadTop="filterHeight">
                <template v-slot:thead>
                    <tr>
                        <th>起飞时间</th>
                        <th>降落时间</th>
                        <th>无人机名称</th>
                        <th>航点飞行任务</th>
                        <th>航点</th>
                        <th>录像</th>
                        <th>图片</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="log in flightLogs" :key="log.id">
                        <td class="date-td">{{log.takeOffTime}}</td>
                        <td class="date-td">{{log.landingTime}}</td>
                        <td>{{log.droneName}}</td>
                        <td>{{ log.taskName }}</td>
                        <td>
                            <span @click="lookWayPoints(log)" class="allow-click" v-if="log.wayPonits">查看</span>
                            <template v-else>-</template>
                        </td>
                        <td>
                            <span @click="lookVideo(log)" class="allow-click" v-if="log.video">查看</span>
                            <template v-else>-</template>
                        </td>
                        <td>
                            <span @click="lookImage(log)" class="allow-click" v-if="log.hasImage">查看</span>
                            <template v-else>-</template>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <pagination  />
        </div>


        <CSDialog :dialog-visible="imageDialog.visible"
                  dialog-title="查看图片"
                  dialog-width="1030px"
                  :dialog-show-confirm-btn="false"
                  dialog-cancel-btn-text="关闭"
                  @onClose="imageDialog.visible = false"
                  dialogHeaderClass ='alert-bg'
        >
            <template v-slot:dialog-content>
                <div class="capture-img">
                    <div class="title">
                        无人机名称：{{imageDialog.data.droneName}}
                    </div>
                    <div class="content" @scroll="loadScreenshot">
                        <div class="item-wrap">
                            <div class="item" v-for="(img, index) in imageDialog.picture" :key="`${img.owner.id}_${index}`">
                                <img :src="img.url" alt="" @click="lookBigImage(imageDialog.data, index)">
                                <span>{{img.lastModified}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
        <CSDialog :dialog-visible="missionInfoDialog.visible"
                  dialog-title="查看航点详情"
                  dialog-width="920px"
                  :dialog-show-confirm-btn="false"
                  dialog-cancel-btn-text="关闭"
                  @onClose="missionInfoDialog.visible = false"
                  dialogHeaderClass ='alert-bg'
        >
            <template v-slot:dialog-content>
                <div style="width: 920px;padding: 30px;">
                    <DroneMap style="height: 640px;" :flight="missionInfoDialog.data.flightHeight" isPreview
                      :back="missionInfoDialog.data.courseReversalHeight" :default-way-points="missionInfoDialog.data.wayPoints" :default-name="missionInfoDialog.data.taskName"
                    />
                </div>
            </template>
        </CSDialog>
        <CSDialog :dialog-visible="bigImageDialog.visible"
                  dialog-title="查看图片"
                  dialog-width="1020px"
                  :dialog-show-confirm-btn="false"
                  dialog-cancel-btn-text="关闭"
                  @onClose="bigImageDialog.visible = false"
                  dialogHeaderClass ='alert-bg'
        >
            <template v-slot:dialog-content>
                <div style="padding: 30px;position: relative">
                    <el-carousel trigger="click" height="540px"
                        :initial-index="bigImageDialog.initIndex"
                        :autoplay="false" :loop="false" indicator-position="none"
                        @change="changeFlightScreenshot"
                    >
                        <el-carousel-item v-for="(img, index) in imageDialog.picture" :key="`${img.owner.id}_${index}`" >
                            <div class="video-drone-name">
                                无人机名称：{{bigImageDialog.data.droneName}}
                                <span class="pull-right">{{img.lastModified}}</span>
                            </div>
                            <img style="width:100%;height:100%" :src="img.url" alt="">
                        </el-carousel-item>
                    </el-carousel>

                </div>
            </template>
        </CSDialog>
        <CSDialog :dialog-visible="videoDialog.visible"
                  dialog-title="查看录像"
                  dialog-width="1020px"
                  :dialog-show-confirm-btn="false"
                  dialog-cancel-btn-text="关闭"
                  @onClose="videoDialog.visible = false"
                  dialogHeaderClass ='alert-bg'                 
        >
            <template v-slot:dialog-content>
                <div style="padding: 30px;position: relative">
                    <div class="video-drone-name" style="top: 30px;">无人机名称：{{videoDialog.data.droneName}}</div>
                    <video :id="'video' + videoDialog.data.id"
                       controls autoplay style="width: 960px;min-height: 540px"
                       :src="videoDialog.videoUrl" controlslist="nodownload noremoteplayback noplaybackrate" disablePictureInPicture
                    ></video>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import dayjs from "dayjs";
import {queryFlightLogUrl} from "@/requestUrl";
import Pagination from "@/components/Pagination";
import DroneMap from "@/views/Drones/DroneMap";
import OSS from "ali-oss";
export default {
    name: "FlightRecord",
    components: {DroneMap, Pagination, CSDialog, CSTable, CSSelect},
    data() {
        return {
            queryParams: {
                regionCode: this.$vc.getCurrentRegion().code,
                startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00'),
                endDate: dayjs().format('YYYY-MM-DD 23:59'),
                search: '',
            },
            videoDialog: {
                visible: false,
                data: {},
            },
            imageDialog: {
                visible: false,
                data: {},
                picture: [],
            },
            bigImageDialog: {
                visible: false,
                data: {},
                initIndex: 0,
            },
            flightLogs: [],
            filterHeight: 60,
            missionInfoDialog: {
                visible: false,
            },
            // 存储临时stsToken
            stsTokenStorage: {},
        }
    },
    mounted() {
        this.getFlightLog();
        this.filterHeight = document.getElementsByClassName('filter-panel')[0].offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.getElementsByClassName('filter-panel')[0].offsetHeight;
        })
        this.$vc.on(this.$route.path, 'pagination_page', 'event', (page) => {
            this.getFlightLog(page);
        })
    },
    methods: {
        /**
         * 查看大图
         * @param {*} flightLog 飞行记录信息
         * @param {Number} index 当前查看图片在所有图片中的下标
         * */
        lookBigImage(flightLog, index) {
            this.bigImageDialog = {
                visible: true,
                data: flightLog,
                initIndex: index,
            };
        },
        changeFlightScreenshot(index) {
            // 当到倒数第4张的时候触发加载后续的图片
            if (index >= this.imageDialog.picture.length - 5) {
                this.imageDialog.loadMore();
            }
        },
        async lookImage(item) {
            console.log(item);
            this.imageDialog = {
                visible: true,
                data: item,
                picture: [],
            }
            const ossClient = await this.createOss('oss-cn-beijing', 'cosmoss-drones');
            console.log(item.imageFolder);
            let result = await ossClient.list({
                prefix: item.imageFolder,
                'max-keys': 50,
            })
            console.log('王');
            console.log(result);
            this.addFlightScreenshot(result, ossClient);
            this.imageDialog.loadMore = async () => {
                if (result.isTruncated) {
                    result = await ossClient.list({ marker : result.nextMarker });
                    this.addFlightScreenshot(result, ossClient);
                }
            }
        },
        // 加载飞行截图
        loadScreenshot(e) {
            console.log(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight);
            if (e.target.scrollHeight - e.target.scrollTop < 100 + e.target.clientHeight) {
                this.imageDialog.loadMore();
            }
        },
        /**
         * 添加飞行截图
         * @param {*} result 查询到的oss结果信息
         * @param {*} ossClient 阿里云oss对象
         * */
        addFlightScreenshot(result, ossClient) {
            this.imageDialog.picture.push(...result.objects.map(item => {
                return {
                    ...item,
                    url: ossClient.signatureUrl(item.name),
                    lastModified: dayjs(item.lastModified).format('YYYY-MM-DD HH:mm:ss')
                }
            }));
        },
        async createOss(region, bucket) {
            const {oss, stsToken} = this.stsTokenStorage;
            if (stsToken && stsToken.expireTime > new Date().getTime()) {
                return oss;
            }
            let res = await this.$fly.get("/account/staff/ossKey");
            let ossKeyData = res.data;
            const ossConfig = {
                    region,
                    accessKeyId: ossKeyData.accessKeyId,
                    accessKeySecret: ossKeyData.accessKeySecret,
                    bucket,
                    stsToken: ossKeyData.token,
                },
                aliOss = new OSS(ossConfig);
            this.stsTokenStorage = {
                stsToken: ossKeyData,
                oss: aliOss
            }
            return aliOss;
        },
        async lookVideo(item) {
            this.videoDialog.data = item;
            const client = await this.createOss('oss-cn-beijing', 'cosmoss-drones');
            const videoUrl = client.signatureUrl(item.video)
            this.videoDialog.videoUrl = videoUrl;
            this.videoDialog.visible = true;
        },
        lookWayPoints(missionInfo) {
            this.missionInfoDialog.visible = true;
            this.missionInfoDialog.data = missionInfo;
            this.missionInfoDialog.data.wayPoints = JSON.parse(missionInfo.wayPonits || []).map(point => point.split(','));
        },
        getFlightLog(pageNo = 1, pageSize = 10) {
            this.$fly.post(queryFlightLogUrl, {
                ...this.queryParams,
                pageNo,
                pageSize
            })
            .then(async res => {
                const flightLogs = [];
                for(let item of res.data.datas) {
                    const ossClient = await this.createOss('oss-cn-beijing', 'cosmoss-drones');
                    let ossResult = await ossClient.list({
                        prefix: item.imageFolder,
                        'max-keys': 1,
                    })
                    if (ossResult.objects) {
                        item.hasImage = ossResult.objects.length > 0;
                    }
                    flightLogs.push(item);
                }
                this.flightLogs = flightLogs;
                if (pageNo === 1) {
                    this.$vc.emit(this.$route.path, "pagination", "init", {
                        total: res.data.total,
                        pageSize,
                        currentPage: pageNo
                    })
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.capture-img
    padding 30px
    //max-height calc(160px * 3)
    .title
        margin-bottom 20px
        font-size 24px
    .content
        max-height calc(160px * 3)
        overflow-y auto
        .item-wrap
            display grid
            grid-template-columns repeat(5, 1fr)
            grid-gap 15px 40px
        .item
            width 160px
            text-align center
            font-size 14px
            img
                width 100%
                display block
                border-radius 5px
.video-drone-name
    width 960px
    background rgba(0, 0, 0, 0.5)
    color #fff
    position absolute
    top 0
    padding 10px 20px
    font-size 20px
    z-index 99
</style>
